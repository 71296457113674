<template>
  <div class="card">
    <div class="card-body">
      <div>
        <h5 style="color: blue; margin-bottom: 30px">
          {{ $t("emploi.emploiEnseignants") }}
        </h5>
        <div class="row">
          <div class="col-8">
            <label>{{ $t("emploi.listeEnseignants") }}</label>
            <a-select
              id="selectClass"
              show-search
              :placeholder="$t('emploi.listeEnseignants')"
              option-filter-prop="children"
              :filter-option="filterOption"
              style="width: 150px; margin-left: 15px"
              @change="
                (val) => {
                  selectedTeacher = val;
                }
              "
            >
              <a-select-option
                v-for="teacher in teachers"
                :key="teacher._id"
                :value="teacher._id"
              >
                {{
                  teacher.employee.firstName + " " + teacher.employee.lastName
                }}
              </a-select-option>
            </a-select>

            <access-schedule
              v-if="user.edtrustAdmin"
              :selectedTeacher="selectedTeacher"
              :classes="classes"
              :selectedTrimester="selectedTrimester"
              style="margin-left: 10px; display: inline"
            />
          </div>
          <div class="col-4">
            <a-dropdown v-if="selectedTeacher" style="float: right">
              <template #overlay>
                <a-menu
                  @click="
                    (val) => {
                      if (val.key == '1') generatePDF('ar');
                      else generatePDF('fr');
                    }
                  "
                >
                  <a-menu-item key="1">
                    {{ $t("language.ar") }}
                  </a-menu-item>
                  <a-menu-item key="2">
                    {{ $t("language.fr") }}
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button>
                <a-icon type="file-pdf" />
                {{ $t("emploi.imprimerEmploi") }}
              </a-button>
            </a-dropdown>
          </div>
        </div>
        <div class="mb-2">
          <a-row :gutter="16">
            <a-col :span="6">
              <span class="ml-2" v-if="selectedTeacher">
                <stat
                  :title="$t('emploi.totaleHeures')"
                  :text="teacherHours.total"
                  icon="clock-circle"
                />
              </span>
            </a-col>
            <a-col :span="6">
              <span class="ml-2" v-if="selectedTeacher">
                <stat
                  :title="$t('emploi.moyenneHeuresJour')"
                  :text="teacherHours.moy"
                  icon="clock-circle"
                />
              </span>
            </a-col>
            <a-col :span="6">
              <span class="ml-2" v-if="selectedTeacher">
                <stat
                  :title="$t('emploi.maximunHeuresJour')"
                  :text="teacherHours.max"
                  icon="clock-circle"
                />
              </span>
            </a-col>
            <a-col :span="6">
              <span class="ml-2" v-if="selectedTeacher">
                <stat
                  :title="$t('emploi.totaleClasses')"
                  :text="teacherHours.totalc"
                  icon="read"
                />
              </span>
            </a-col>
          </a-row>
        </div>

        <a-alert
          v-if="updating"
          class="mb-2"
          :message="$t('emploi.modeModif')"
          :description="$t('emploi.desc')"
          type="info"
          show-icon
        />
        <!-- START ADD DRAWER -->
        <a-drawer
          :title="$t('emploi.ajouterSeance') + ' : ' + selectedTime"
          :width="720"
          :visible="visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="onClose"
        >
          <a-form :form="form" layout="vertical" hide-required-mark>
            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item :label="$t('emploi.classe')">
                  <a-select
                    show-search
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    @change="
                      (val) => {
                        handleClassChange(val);
                        selectedSubject = null;
                      }
                    "
                    v-decorator="[
                      'classeRoom',
                      {
                        rules: [
                          {
                            required: true,
                            message: $t('emploi.choisirClasse'),
                          },
                        ],
                      },
                    ]"
                    :placeholder="$t('emploi.choisirClasse')"
                  >
                    <a-select-opt-group :label="$t('emploi.disponible')">
                      <a-select-option
                        v-for="classe in filtredClasses.disponible"
                        :key="classe._id"
                        :value="classe._id"
                      >
                        {{ classe.name }}
                      </a-select-option>
                    </a-select-opt-group>
                    <a-select-opt-group :label="$t('emploi.indisponible')">
                      <a-select-option
                        :disabled="true"
                        v-for="classe in filtredClasses.indisponible"
                        :key="classe._id"
                        :value="classe._id"
                      >
                        {{ classe.name }}
                      </a-select-option>
                    </a-select-opt-group>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label="$t('emploi.matiere')">
                  <!-- <a-select
                    :disabled="!selectedLevel"
                    :loading="loadingSubjects"
                    v-model="selectedSubject"
                    show-search
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    v-decorator="[
                      'subject',
                      {
                        rules: [
                          {
                            required: true,
                            message: $t('emploi.choisirMatiere'),
                          },
                        ],
                      },
                    ]"
                    :placeholder="$t('emploi.choisirMatiere')"
                  >
                    <a-select-option
                      v-for="module in modules"
                      :key="module._id"
                      :value="module._id"
                    >
                      {{ module.name }}
                    </a-select-option>
                    <a-select-option
                      v-for="subject in subjects"
                      :key="subject._id"
                      :value="subject._id"
                    >
                      {{ subject.name }}
                    </a-select-option>
                  </a-select> -->
                  <multiselect
                    v-model="selectedSubject"
                    :disabled="!selectedLevel"
                    :placeholder="$t('emploi.choisirMatiere')"
                    :options="[...modulesSpecific, ...modules, ...subjects]"
                    :customLabel="
                      () =>
                        selectedSubject
                          ? selectedSubject.name
                          : $t('emploi.choisirMatiere')
                    "
                    :custom-label="$t('emploi.choisirMatiere')"
                    :close-on-select="true"
                    :preserve-search="true"
                    :maxHeight="400"
                    label="_id"
                    track-by="_id"
                    :preselect-first="true"
                    :multiple="false"
                    :allow-empty="false"
                    @input="handleSubjectChange"
                  >
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="align-text-top">{{
                          props.option.name
                        }}</span>
                        <br />
                        <span
                          v-if="props.option.type == 'MP'"
                          class="option__small font-size-12 text-primary"
                        >
                          <b> module pédagogique</b>
                        </span>
                        <span
                          v-else-if="props.option.type == 'MS'"
                          class="option__small font-size-12 text-primary"
                        >
                          <b> module pilote</b>
                        </span>
                        <span
                          v-else-if="
                            props.option.type == 'S' && props.option.special
                          "
                          class="option__small font-size-12 text-danger"
                        >
                          <b>Select matière supplémentaire </b>
                        </span>
                        <span
                          v-else-if="props.option.type == 'S'"
                          class="option__small font-size-12"
                        >
                          <b>Select matière </b>
                        </span>
                      </div>
                    </template>
                  </multiselect>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item :label="$t('emploi.salle')">
                  <a-select
                    show-search
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    v-decorator="[
                      'room',
                      {
                        rules: [
                          {
                            required: false,
                            message: $t('emploi.choisirSalle'),
                          },
                        ],
                      },
                    ]"
                    :placeholder="$t('emploi.choisirSalle')"
                  >
                    <a-select-opt-group :label="$t('emploi.disponible')">
                      <a-select-option
                        v-for="room in filtredRooms.disponible"
                        :key="room._id"
                        :value="room._id"
                      >
                        {{ room.name }}
                      </a-select-option>
                    </a-select-opt-group>
                    <a-select-opt-group :label="$t('emploi.indisponible')">
                      <a-select-option
                        v-for="room in filtredRooms.indisponible"
                        :key="room._id"
                        :value="room._id"
                      >
                        {{ room.name }}
                      </a-select-option>
                    </a-select-opt-group>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label="$t('emploi.duree')">
                  <a-select
                    show-search
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    v-model="selectedDuration"
                    v-decorator="[
                      'duration',
                      {
                        rules: [
                          {
                            required: true,
                            message: $t('emploi.choisirDuree'),
                          },
                        ],
                      },
                    ]"
                    :placeholder="$t('emploi.choisirDuree')"
                  >
                    <a-select-option
                      v-for="duration in [
                        { name: '30min', value: 2 },
                        { name: '45min', value: 3 },
                        { name: '1h', value: 4 },
                        { name: '1h15', value: 5 },
                        { name: '1h30', value: 6 },
                        { name: '1h45', value: 7 },
                        { name: '2h', value: 8 },
                        { name: '2h15', value: 9 },
                        { name: '2h30', value: 10 },
                      ]"
                      :key="duration.value"
                      :value="duration.value"
                    >
                      {{ duration.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item :label="$t('emploi.name')">
                  <a-input
                    v-decorator="[
                      'name',
                      {
                        rules: [
                          {
                            required: true,
                            message: $t('emploi.choisirName'),
                          },
                        ],
                      },
                    ]"
                    :placeholder="$t('emploi.choisirName')"
                  >
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col
                :span="12"
                v-if="
                  selectedSubject && ['MS', 'MP'].includes(selectedSubject.type)
                "
              >
                <a-form-item :label="$t('emploi.includedSubjects')">
                  <a-card class="p-0">
                    <a-checkbox-group v-model="includedSubjects">
                      <a-row
                        v-for="subject in selectedSubject.subjects"
                        :key="subject._id"
                      >
                        <a-checkbox checked :value="subject._id">
                          {{ subject.name }}
                        </a-checkbox>
                      </a-row>
                    </a-checkbox-group>
                  </a-card>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>

          <a-alert
            v-if="submitError"
            :message="submitError.message"
            :description="submitError.description"
            type="error"
            show-icon
          />

          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="onClose">
              {{ $t("action.annuler") }}
            </a-button>
            <a-button
              type="primary"
              @click="handleSubmit"
              :loading="loadingAddBtn"
              :disabled="loadingAddBtn"
            >
              {{ $t("action.ajouter") }}
            </a-button>
          </div>
        </a-drawer>
        <!-- END ADD DRAWER -->
      </div>

      <schedule
        :loading="loading"
        :errorDrag="errorDrag"
        :dataGrid="data"
        :updating="updating"
        @sched="showUpdateDrawer"
        @sched_drag_update="handleUpdateDrag"
        @reload="
          () => {
            this.updating = false;
          }
        "
      />

      <!-- START UPDATE DRAWER -->
      <a-drawer
        :title="$t('emploi.modifierSeance') + ' : ' + selectedTime"
        :width="720"
        :visible="updateVisible"
        :body-style="{ paddingBottom: '80px' }"
        @close="onClose"
      >
        <a-row v-if="loadingScheduleData">
          <a-col :span="16" :offset="11">
            <a-spin size="large" />
          </a-col>
        </a-row>
        <a-form v-else :form="form" layout="vertical" hide-required-mark>
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-item :label="$t('emploi.classe')">
                <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="
                    (val) => {
                      handleClassChange(val);
                      selectedSubject = null;
                    }
                  "
                  v-decorator="[
                    'classeRoom',
                    {
                      initialValue: selectedSchedule
                        ? selectedSchedule.classeRoom._id
                        : null,
                      rules: [
                        { required: true, message: $t('emploi.choisirClasse') },
                      ],
                    },
                  ]"
                  :placeholder="$t('emploi.choisirClasse')"
                >
                  <a-select-opt-group :label="$t('emploi.disponible')">
                    <a-select-option
                      v-for="classe in filtredClasses.disponible"
                      :key="classe._id"
                      :value="classe._id"
                    >
                      {{ classe.name }}
                    </a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group :label="$t('emploi.indisponible')">
                    <a-select-option
                      v-for="classe in filtredClasses.indisponible"
                      :key="classe._id"
                      :value="classe._id"
                    >
                      {{ classe.name }}
                    </a-select-option>
                  </a-select-opt-group>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="$t('emploi.matiere')">
                <multiselect
                  v-model="selectedSubject"
                  :disabled="!selectedLevel"
                  :loading="loadingSubjects"
                  :placeholder="$t('emploi.choisirMatiere')"
                  :options="[...modulesSpecific, ...modules, ...subjects]"
                  :customLabel="
                    () =>
                      selectedSubject
                        ? selectedSubject.name
                        : $t('emploi.choisirMatiere')
                  "
                  :custom-label="$t('emploi.choisirMatiere')"
                  :close-on-select="true"
                  :preserve-search="true"
                  :maxHeight="400"
                  label="_id"
                  track-by="_id"
                  :preselect-first="true"
                  :multiple="false"
                  :allow-empty="false"
                  @input="handleSubjectChange"
                >
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="align-text-top">{{
                        props.option.name
                      }}</span>
                      <br />
                      <span
                        v-if="props.option.type == 'MP'"
                        class="option__small font-size-12 text-primary"
                      >
                        <b> module pédagogique</b>
                      </span>
                      <span
                        v-else-if="props.option.type == 'MS'"
                        class="option__small font-size-12 text-primary"
                      >
                        <b> module pilote</b>
                      </span>
                      <span
                        v-else-if="
                          props.option.type == 'S' && props.option.special
                        "
                        class="option__small font-size-12 text-danger"
                      >
                        <b>Select matière supplémentaire </b>
                      </span>
                      <span
                        v-else-if="props.option.type == 'S'"
                        class="option__small font-size-12"
                      >
                        <b>Select matière </b>
                      </span>
                    </div>
                  </template>
                </multiselect>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-item :label="$t('emploi.salle')">
                <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-decorator="[
                    'room',
                    {
                      initialValue:
                        selectedSchedule && selectedSchedule.room
                          ? selectedSchedule.room._id
                          : null,
                      rules: [
                        { required: false, message: $t('emploi.choisirSalle') },
                      ],
                    },
                  ]"
                  :placeholder="$t('emploi.choisirSalle')"
                >
                  <a-select-opt-group :label="$t('emploi.disponible')">
                    <a-select-option
                      v-for="room in filtredRooms.disponible"
                      :key="room._id"
                      :value="room._id"
                    >
                      {{ room.name }}
                    </a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group :label="$t('emploi.indisponible')">
                    <a-select-option
                      v-for="room in filtredRooms.indisponible"
                      :key="room._id"
                      :value="room._id"
                    >
                      {{ room.name }}
                    </a-select-option>
                  </a-select-opt-group>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="$t('emploi.duree')">
                <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="selectedDuration"
                  v-decorator="[
                    'duration',
                    {
                      initialValue: selectedSchedule
                        ? selectedSchedule.duration
                        : null,
                      rules: [
                        { required: true, message: $t('emploi.choisirDuree') },
                      ],
                    },
                  ]"
                  :placeholder="$t('emploi.choisirDuree')"
                >
                  <a-select-option
                    v-for="duration in [
                      { name: '45min', value: 3 },
                      { name: '1h', value: 4 },
                      { name: '1h15', value: 5 },
                      { name: '1h30', value: 6 },
                      { name: '1h45', value: 7 },
                      { name: '2h', value: 8 },
                      { name: '2h15', value: 9 },
                      { name: '2h30', value: 10 },
                    ]"
                    :key="duration.value"
                    :value="duration.value"
                  >
                    {{ duration.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-item :label="$t('emploi.name')">
                <a-input
                  v-decorator="[
                    'name',
                    {
                      initialValue: selectedSchedule
                        ? selectedSchedule.name
                          ? selectedSchedule.name
                          : selectedSchedule.subject.name
                        : '',
                      rules: [
                        {
                          required: true,
                          message: $t('emploi.choisirName'),
                        },
                      ],
                    },
                  ]"
                  :placeholder="$t('emploi.choisirName')"
                >
                </a-input>
              </a-form-item>
            </a-col>
            <a-col
              :span="12"
              v-if="
                selectedSubject && ['MS', 'MP'].includes(selectedSubject.type)
              "
            >
              <a-form-item :label="$t('emploi.includedSubjects')">
                <a-card class="p-0">
                  <a-checkbox-group v-model="includedSubjects">
                    <a-row
                      v-for="subject in selectedSubject.subjects"
                      :key="subject._id"
                    >
                      <a-checkbox checked :value="subject._id">
                        {{ subject.name }}
                      </a-checkbox>
                    </a-row>
                  </a-checkbox-group>
                </a-card>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>

        <a-alert
          v-if="submitError"
          :message="submitError.message"
          :description="submitError.description"
          type="error"
          show-icon
        />

        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-popconfirm
            title="Sure?"
            ok-text="Oui"
            cancel-text="Non"
            @confirm="deleteSchedule"
          >
            <a-button :style="{ marginRight: '8px' }" type="danger">
              {{ $t("action.supprimer") }}
            </a-button>
          </a-popconfirm>
          <a-button :style="{ marginRight: '8px' }" @click="onClose">
            {{ $t("action.annuler") }}
          </a-button>
          <a-button type="primary" @click="handleUpdate">
            {{ $t("action.modifier") }}
          </a-button>
        </div>
      </a-drawer>
      <!-- END UPDATE DRAWER -->
    </div>
  </div>
</template>
<script>
/*eslint-disable */

import apiClient from "@/services/axios";
import { mapState } from "vuex";
import schedule from "./component/schedule";
import accessSchedule from "./component/accessSchedule";

import stat from "./component/stat";
import JsPDF from "jspdf";
import "jspdf-autotable";

import moment from "moment";
export default {
  components: {
    schedule: schedule,
    stat: stat,
    accessSchedule,
  },
  async created() {
    this.tableLoading = true;

    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
            teachers: 1,
            level: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data));

    await apiClient
      .post("/teachers/filter", {
        query: { status: "active" },
      })
      .then((res) => (this.teachers = res.data));

    await apiClient
      .post("/rooms/filter", {
        query: {},
      })
      .then((res) => (this.rooms = res.data));

    this.tableLoading = false;
    this.filtredTable = this.activeData;

    const building = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    );

    this.schoolType = building.type;

    this.smartScheduleAccess = building.access.edManager["smartSchedule"];
  },
  data() {
    return {
      smartScheduleAccess: false,
      visibleAccessDrawer: false,
      resetSchedulesLoading: false,
      loadingScheduleData: false,
      updating: false,
      selectedSchedule: null,
      schoolType: null,
      filtredTable: [],
      filtredClasses: {},
      filtredRooms: {},
      subjects: [],
      modules: [],
      modulesSpecific: [],
      includedSubjects: [],
      selectedLevel: null,
      selectedSubject: null,
      loadingSubjects: false,
      teachers: [],
      rooms: [],
      selectedTeacher: null,
      tableLoading: false,
      data: [],
      dataAllTeachers: [],
      activeData: [],
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      rowData: [],
      data1: null,
      classes: [],
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      form: this.$form.createForm(this),
      visible: false,
      updateVisible: false,
      selectedDay: null,
      selectedTime: null,
      loading: false,
      selectedTrimester: 0,
      submitError: null,
      selectedDuration: null,
      errorDrag: false,
      loadingAddBtn: false,
    };
  },
  methods: {
    handleSubjectChange() {
      if (this.selectedSubject) {
        this.form.setFieldsValue({ name: this.selectedSubject.name });
        if (["MS", "MP"].includes(this.selectedSubject.type)) {
          this.includedSubjects = this.selectedSubject.subjects.map(
            (s) => s._id
          );
        }
      }
    },
    async handleClassChange(value, record) {
      this.selectedLevel = this.classes.find(
        (item) => item._id === value
      ).level;

      this.loadingSubjects = true;
      await apiClient
        .post("/module/filter", {
          query: { status: "active", level: Number(this.selectedLevel) },
        })
        .then((res) => {
          this.modules = res.data
            .filter((m) => m.status == "active")
            .map((m) => {
              m.type = "MP";
              return m;
            });

          this.modules.map((m) => {
            this.subjects = [];
            this.subjects.push(
              ...m.subjects
                .filter((s) => s.status == "active")
                .map((s) => {
                  s.type = "S";
                  return s;
                })
            );
          });
        });

      await apiClient
        .post("/moduleSpecific/filter", {
          query: { status: "active", level: Number(this.selectedLevel) },
        })
        .then((res) => {
          this.modulesSpecific = res.data
            .filter((m) => m.status == "active")
            .map((m) => {
              m.type = "MS";
              return m;
            });

          this.modulesSpecific.map((m) => {
            this.subjects.push(
              ...m.subjects
                .filter(
                  (s) =>
                    s.status == "active" &&
                    !this.subjects.find((ss) => s._id == ss._id)
                )
                .map((s) => {
                  s.type = "S";
                  return s;
                })
            );
          });
        });

      apiClient
        .post("/subject/filter", {
          query: { status: "active" },
        })
        .then((res) => {
          res.data
            .filter((m) => !this.subjects.find((s) => s._id == m._id))
            .map((m) => {
              m.type = "S";
              m.special = true;
              this.subjects.push(m);
            });

          const ls = [
            ...this.modulesSpecific,
            ...this.modules,
            ...this.subjects,
          ];
          const sub = ls.find((s) => s._id == record.subject._id);
          this.selectedSubject = sub ? sub : null;

          if (
            this.selectedSubject &&
            ["MS", "MP"].includes(this.selectedSubject.type)
          )
            if (record.includedSubjects)
              this.includedSubjects = record.includedSubjects;
            else
              this.includedSubjects = this.selectedSubject.subjects.map(
                (s) => s._id
              );
        })
        .finally(() => {
          this.loadingSubjects = false;
          this.loadingScheduleData = false;
        });
    },
    updatingSched(updating) {
      this.updating = updating;
    },
    showUpdateDrawer(record, day, time) {
      apiClient
        .post("/schedules/disponiblity/filter", {
          query: {
            who: "teacher",
            time,
            day,
            teacher: this.selectedTeacher,
          },
        })
        .then(({ data }) => {
          this.filtredRooms = data.rooms;
          this.filtredClasses = data.classes;
        });

      if (!this.selectedTeacher) {
        this.$message.error(this.$t("emploi.choisirEnseignant"));
        return;
      }
      this.selectedDay = day;
      this.selectedTime = time;
      if (record) {
        this.loadingScheduleData = true;
        this.handleClassChange(record.classeRoom._id, record);
        this.selectedSchedule = record;

        this.updateVisible = true;
      } else {
        this.visible = true;
      }
    },
    showDrawer() {
      if (!this.selectedTeacher) {
        this.$message.error(this.$t("emploi.choisirEnseignant"));
        return;
      }
      this.visible = true;
    },
    onClose() {
      this.visible = false;
      this.updateVisible = false;
      this.selectedDay = null;
      this.selectedTime = null;
      this.selectedSchedule = null;
      this.submitError = null;
      this.form.resetFields();
    },
    handleSubmit(e) {
      this.submitError = null;
      e.preventDefault();
      this.form.rese;
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loadingAddBtn = true;
          values.teacher = this.selectedTeacher;
          values.trimester = this.selectedTrimester;
          values.day = this.selectedDay;
          values.time = this.selectedTime;
          const type = this.getSelectedSubjectType(this.selectedSubject._id);

          values.type = type;
          values.subject = type == "S" ? this.selectedSubject._id : null;
          values.module = type == "MP" ? this.selectedSubject._id : null;
          values.moduleSpecific =
            type == "MS" ? this.selectedSubject._id : null;

          if (
            ["MS", "MP"].includes(values.type) &&
            this.includedSubjects.length != this.selectedSubject.subjects.length
          )
            values.includedSubjects = this.includedSubjects;
          else values.includedSubjects = null;

          apiClient
            .put(`/schedules`, { data: values })
            .then((res) => {
              if (res.data.type == "MP") res.data.subject = res.data.module;
              else if (res.data.type == "MS")
                res.data.subject = res.data.moduleSpecific;

              res.data.subject.type = res.data.type;

              this.data.push(res.data);
              this.$message.success(this.$t("success.seanceAjout"));
              this.onClose();
            })
            .catch((e) => {
              if (e.response.status == 400) {
                if (e.response.data.errorId == 1) {
                  let schedule = e.response.data.schedule;
                  this.$message.error(this.$t("error.classeIndis"));
                  this.submitError = {
                    message: this.$t("error.classeIndis"),
                    description: $t("error.classPriseEntre", {
                      name: schedule.classeRoom.name,
                      start: schedule.time,
                      end: schedule.ends,
                    }),
                  };
                }

                if (e.response.data.errorId == 2) {
                  let schedule = e.response.data.schedule;
                  this.$message.error(this.$t("error.enseignantIndis"));

                  schedule.subject.type = schedule.type;
                  if (schedule.type == "MP") schedule.subject = schedule.module;
                  else if (schedule.type == "MS")
                    schedule.subject = schedule.moduleSpecific;

                  this.submitError = {
                    message: "L'enseignant est indisponible",
                    description: `L'enseignant ${schedule.teacher.firstName} ${schedule.teacher.lastName} enseigne la classe ${schedule.classeRoom.name} la matiere ${schedule.subject.name} entre ${schedule.time} et ${schedule.ends}`,
                  };
                }

                if (e.response.data.errorId == 3) {
                  let schedule = e.response.data.schedule;
                  this.$message.error(this.$t("error.salleIndis"));
                  this.submitError = {
                    message: this.$t("error.salleIndis"),
                    description: $t("error.classPriseEntre", {
                      name: schedule.classeRoom.name,
                      start: schedule.time,
                      end: schedule.ends,
                    }),
                  };
                }

                if (e.response.data.errorId == 4) {
                  this.$message.error(this.$t("error.capacite"));
                }
              }
            })
            .finally(() => (this.loadingAddBtn = false));
        }
      });
    },
    handleUpdateDrag(index, day, time, duration) {
      this.errorDrag = false;

      let schedule = this.data.find((el) => el._id == index);

      let values = {
        teacher: this.selectedTeacher,
        trimester: this.selectedTrimester,
        time: time,
        day: day,
        duration: duration,
        classeRoom: schedule.classeRoom._id,
        room: schedule.room ? schedule.room._id : null,
      };

      values.type = schedule.type || "S";
      values.subject = values.type == "S" ? schedule.subject._id : null;
      values.module = values.type == "MP" ? schedule.module._id : null;
      values.moduleSpecific =
        values.type == "MS" ? schedule.moduleSpecific._id : null;

      apiClient
        .patch(`/schedules/` + index, { data: values })
        .then((res) => {
          if (res.data.type == "MP") res.data.subject = res.data.module;
          else if (res.data.type == "MS")
            res.data.subject = res.data.moduleSpecific;

          res.data.subject.type = res.data.type;

          this.$message.success("Sceance modifier");
          let index = this.data.findIndex((el) => el._id == res.data._id);
          this.data.splice(index, 1, res.data);
        })
        .catch((e) => {
          this.errorDrag = true;
          if (e.response.status == 400) {
            if (e.response.data.errorId == 1) {
              let schedule = e.response.data.schedule;
              this.$message.error(this.$t("error.classeIndis"));
              this.submitError = {
                message: this.$t("error.classeIndis"),
                description: $t("error.classPriseEntre", {
                  name: schedule.classeRoom.name,
                  start: schedule.time,
                  end: schedule.ends,
                }),
              };
            }

            if (e.response.data.errorId == 2) {
              let schedule = e.response.data.schedule;
              this.$message.error(this.$t("error.enseignantIndis"));

              schedule.subject.type = schedule.type;
              if (schedule.type == "MP") schedule.subject = schedule.module;
              else if (schedule.type == "MS")
                schedule.subject = schedule.moduleSpecific;

              this.submitError = {
                message: "L'enseignant est indisponible",
                description: `L'enseignant ${schedule.teacher.employee.firstName} ${schedule.teacher.employee.lastName} enseigne la classe ${schedule.classeRoom.name} la matiere ${schedule.subject.name} entre ${schedule.time} et ${schedule.ends}`,
              };
            }

            if (e.response.data.errorId == 3) {
              let schedule = e.response.data.schedule;
              this.$message.error(this.$t("error.salleIndis"));
              this.submitError = {
                message: this.$t("error.salleIndis"),
                description: $t("error.classPriseEntre", {
                  name: schedule.classeRoom.name,
                  start: schedule.time,
                  end: schedule.ends,
                }),
              };
            }

            if (e.response.data.errorId == 4) {
              this.$message.error(this.$t("error.capacite"));
            }
          }
        });
    },
    getSelectedSubjectType(id) {
      const res = [
        ...this.modulesSpecific,
        ...this.subjects,
        ...this.modules,
      ].find((s) => s._id == id);
      if (res) return res.type;
    },
    handleUpdate(e) {
      this.submitError = null;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values.teacher = this.selectedTeacher;
          values.trimester = this.selectedTrimester;
          values.time = this.selectedTime;
          values.day = this.selectedDay;

          const type = this.getSelectedSubjectType(this.selectedSubject._id);

          values.type = type;
          values.subject = type == "S" ? this.selectedSubject._id : null;
          values.module = type == "MP" ? this.selectedSubject._id : null;
          values.moduleSpecific =
            type == "MS" ? this.selectedSubject._id : null;

          if (["MS", "MP"].includes(values.type))
            if (
              this.includedSubjects.length !=
              this.selectedSubject.subjects.length
            )
              values.includedSubjects = this.includedSubjects;
            else values.includedSubjects = null;
          else values.includedSubjects = null;

          apiClient
            .patch(`/schedules/` + this.selectedSchedule._id, { data: values })
            .then((res) => {
              if (res.data.type == "MP") res.data.subject = res.data.module;
              else if (res.data.type == "MS")
                res.data.subject = res.data.moduleSpecific;

              res.data.subject.type = res.data.type;
              this.$message.success("Sceance modifier");
              let index = this.data.findIndex(
                (el) => el._id == this.selectedSchedule._id
              );
              this.data.splice(index, 1, res.data);
              this.onClose();
            })
            .catch((e) => {
              if (e.response.status == 400) {
                if (e.response.data.errorId == 1) {
                  let schedule = e.response.data.schedule;
                  this.$message.error(this.$t("error.classeIndis"));
                  this.submitError = {
                    message: this.$t("error.classeIndis"),
                    description: $t("error.classPriseEntre", {
                      name: schedule.classeRoom.name,
                      start: schedule.time,
                      end: schedule.ends,
                    }),
                  };
                }

                if (e.response.data.errorId == 2) {
                  let schedule = e.response.data.schedule;
                  this.$message.error(this.$t("error.enseignantIndis"));

                  schedule.subject.type = schedule.type;
                  if (schedule.type == "MP") schedule.subject = schedule.module;
                  else if (schedule.type == "MS")
                    schedule.subject = schedule.moduleSpecific;

                  this.submitError = {
                    message: "L'enseignant est indisponible",
                    description: `L'enseignant ${schedule.teacher.employee.firstName} ${schedule.teacher.employee.lastName} enseigne la classe ${schedule.classeRoom.name} la matiere ${schedule.subject.name} entre ${schedule.time} et ${schedule.ends}`,
                  };
                }

                if (e.response.data.errorId == 3) {
                  let schedule = e.response.data.schedule;
                  this.$message.error(this.$t("error.salleIndis"));
                  this.submitError = {
                    message: this.$t("error.salleIndis"),
                    description: $t("error.classPriseEntre", {
                      name: schedule.classeRoom.name,
                      start: schedule.time,
                      end: schedule.ends,
                    }),
                  };
                }

                if (e.response.data.errorId == 4) {
                  this.$message.error(this.$t("error.capacite"));
                }
              }
            });
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    deleteSchedule() {
      if (this.selectedSchedule) {
        apiClient
          .delete(`/schedules/` + this.selectedSchedule._id)
          .then(() => {
            this.$message.success(this.$t("success.seanceSupp"));
            this.data = this.data.filter(
              (el) => el._id != this.selectedSchedule._id
            );
            this.onClose();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getTimes() {
      if (this.selectedTime) {
        let time = this.selectedTime.split(":");
        let times = [time.join(":")];
        time[1] = Number(time[1]) + 15;
        times[1] = time.join(":");
        time[1] = Number(time[1]) + 15;
        times[2] = time.join(":");
        time[1] = Number(time[1]) + 15;
        times[3] = time.join(":");
        return times;
      }
      return [];
    },
    endTime(time, duration) {
      let nextTime = time.split(":");
      let hours = Math.floor(duration / 4);
      let halfs = duration % 4;
      nextTime[0] = Number(nextTime[0]) + hours;
      for (var i = 0; i < halfs; i++) {
        nextTime[1] = Number(nextTime[1]) + 15;
        if (nextTime[1] == 60) {
          nextTime[1] = "00";
          nextTime[0] = Number(nextTime[0]) + 1;
        }
      }
      return nextTime.join(":");
    },
    generatePDF(lang = "fr") {
      this.$gtag.event("Imp Emploi Enseignant", {
        event_category: "Impression PDF",
        event_label: "Emploi - Enseignants section",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      var doc = new JsPDF("landscape");
      const pdfWidth = doc.internal.pageSize.getWidth();
      doc.setFont("Amiri");
      doc.addImage(imgData, "JPEG", 8, 5, 30, 30);

      doc.setFont("Amiri");
      doc.setFontSize(15);
      var today = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      let teacher = this.teachers.find((el) => el._id == this.selectedTeacher);
      let fullTeacherName =
        teacher.employee.firstName + " " + teacher.employee.lastName;

      doc.setFontSize(10);

      doc.text(
        230,
        10,
        lang == "fr"
          ? "Imprimé le : " + " " + today.toLocaleDateString("fr-FR", options)
          : " تم الطباعة بتاريخ " + today.toLocaleDateString("ar-TN", options)
      );
      doc.setFontSize(lang == "ar" ? 20 : 18);

      const title =
        lang == "fr"
          ? `Emplois d'${fullTeacherName}`
          : `${fullTeacherName} جدول أوقات`;
      const width = doc.getTextWidth(title);
      doc.text(title, pdfWidth / 2 - width / 2, 30);

      const times = [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
      ];

      const array = [];

      const rowsCount = 40;
      const cellToDelete = {};
      let position = 0;

      for (let i = 0; i < rowsCount; i++) {
        array[i] = new Array(7).fill(null);
      }

      for (let i = 0; i < times.length - 1; i++) {
        let currentTime = times[i] + " - " + times[i + 1];
        if (lang != "fr") currentTime = times[i + 1] + " - " + times[i];
        array[i * 4][0] = {
          content: currentTime,
          rowSpan: 4,
          styles: { halign: "center", valign: "middle" },
        };
        for (let t = 1; t < 4; t++) array[i * 4 + t][0] = cellToDelete;
      }

      for (let i = 0; i < times.length - 1; i++) {
        // H:00
        let schedules = this.data.filter((el) => el.time == times[i]);

        schedules.forEach((sched) => {
          const day = Number(sched.day) + 1;
          const schedName =
            sched.type == "MP"
              ? sched.module.name
              : sched.type == "MS"
              ? sched.moduleSpecific.name
              : sched.subject.name;
          const cellValue =
            (sched.name ? sched.name : schedName) +
            "\r\n" +
            sched.classeRoom.name;
          // +
          // "\r\n" +
          // sched.room.name;

          array[position][day] = {
            content: cellValue,
            rowSpan: sched.duration,
            styles: { halign: "center", valign: "middle" },
          };

          for (let t = 1; t < sched.duration; t++)
            array[position + t][day] = cellToDelete;
        });
        position++;

        // H:15
        schedules = this.data.filter(
          (el) => el.time == [times[i].split(":")[0], "15"].join(":")
        );
        schedules.forEach((sched) => {
          const day = Number(sched.day) + 1;
          const schedName =
            sched.type == "MP"
              ? sched.module.name
              : sched.type == "MS"
              ? sched.moduleSpecific.name
              : sched.subject.name;
          const cellValue =
            (sched.name ? sched.name : schedName) +
            "\r\n" +
            sched.classeRoom.name;
          // +
          // "\r\n" +
          // sched.room.name;

          array[position][day] = {
            content: cellValue,
            rowSpan: sched.duration,
            styles: { halign: "center", valign: "middle" },
          };

          for (let t = 1; t < sched.duration; t++)
            array[position + t][day] = cellToDelete;
        });
        position++;

        // H:30
        schedules = this.data.filter(
          (el) => el.time == [times[i].split(":")[0], "30"].join(":")
        );

        schedules.forEach((sched) => {
          const day = Number(sched.day) + 1;
          const schedName =
            sched.type == "MP"
              ? sched.module.name
              : sched.type == "MS"
              ? sched.moduleSpecific.name
              : sched.subject.name;
          const cellValue =
            (sched.name ? sched.name : schedName) +
            "\r\n" +
            sched.classeRoom.name;
          // +
          // "\r\n" +
          // sched.room.name;

          array[position][day] = {
            content: cellValue,
            rowSpan: sched.duration,
            styles: { halign: "center", valign: "middle" },
          };

          for (let t = 1; t < sched.duration; t++)
            array[position + t][day] = cellToDelete;
        });
        position += 1;

        // H:45
        schedules = this.data.filter(
          (el) => el.time == [times[i].split(":")[0], "45"].join(":")
        );
        schedules.forEach((sched) => {
          const day = Number(sched.day) + 1;
          const schedName =
            sched.type == "MP"
              ? sched.module.name
              : sched.type == "MS"
              ? sched.moduleSpecific.name
              : sched.subject.name;
          const cellValue =
            (sched.name ? sched.name : schedName) +
            "\r\n" +
            sched.classeRoom.name;
          // +
          // "\r\n" +
          // sched.room.name;

          array[position][day] = {
            content: cellValue,
            rowSpan: sched.duration,
            styles: { halign: "center", valign: "middle" },
          };

          for (let t = 1; t < sched.duration; t++)
            array[position + t][day] = cellToDelete;
        });
        position++;
      }

      if (lang != "fr")
        for (let row = 0; row < rowsCount; row++) {
          array[row] = array[row].reverse();
        }

      // fix table
      for (let row = 0; row < rowsCount; row++) {
        array[row] = array[row].filter((cell) => cell !== cellToDelete);
      }
      doc.autoTable({
        columns:
          lang == "fr"
            ? [
                "Temps",
                "Lundi",
                "Mardi",
                "Mercredi",
                "Jeudi",
                "Vendredi",
                "Samedi",
              ]
            : [
                "السبت",
                "الجمعة",
                "الخميس",
                "الأربعاء",
                "الثلاثاء",
                "الأثنين",
                "الأوقات",
              ],
        body: array,

        theme: "grid",
        styles: { font: "Amiri", fontSize: 9 },
        margin: { top: 40 },
        headStyles: {
          valign: "middle",
          halign: "center",
          fontSize: 12,
          fontStyle: "bold",
        },
        willDrawCell: (data) => {
          if (data.section != "head") {
            data.row.height = 3.1;
            data.cell.contentHeight = 3.1;
            data.cellHeight = 3.1;
            data.cell.height = 3.1 * data.cell.rowSpan;
          }
        },
        didParseCell: function (data) {
          if (data.column.index === 0) {
            data.cell.styles.fontStyle = "bold";
          }
        },
      });

      doc.save(title + ".pdf");
    },
    resetSchedules() {
      this.resetSchedulesLoading = true;
      apiClient
        .post("/smartSchedule/schedJobParam/unmigrate/", {})
        .then((res) => {
          if (res.data.type == "success")
            this.$message.success(this.$t("smartSchedule." + res.data.message));
          else if (res.data.type == "warning")
            this.$message.warning(this.$t("smartSchedule." + res.data.message));
          else if (res.data.type == "error")
            this.$message.error(this.$t("smartSchedule." + res.data.message));

          if (res.data.type == "success")
            setTimeout(() => {
              window.location.reload();
            }, 100);
        })
        .catch((err) => {
          this.$message.error(this.$t("error.error"));
        })
        .finally(() => {
          this.resetSchedulesLoading = false;
        });
    },
  },
  computed: {
    ...mapState(["settings", "user"]),
    teacherHours() {
      let hours = {
        total: 0,
        moy: 0,
        min: 0,
        totalc: 0,
      };

      if (this.data) {
        let hoursPerDay = [0, 0, 0, 0, 0, 0];
        let classes = [];
        let minutes = 0;
        for (let i = 0; i < this.data.length; i++) {
          hoursPerDay[this.data[i].day] += this.data[i].duration * 15;
          minutes = minutes + this.data[i].duration * 15;
          classes.push(this.data[i].classeRoom.name);
        }
        hours.moy =
          Math.floor(minutes / 6 / 60) +
          " H " +
          Math.floor((minutes / 6) % 60) +
          " m";
        hours.total = Math.floor(minutes / 60) + " H " + (minutes % 60) + " m";
        let max = Math.max(...hoursPerDay);
        hours.max = Math.floor(max / 60) + " H " + (max % 60) + " m";
        hours.totalc = [...new Set(classes)].length;
      }
      return hours;
    },
  },
  watch: {
    selectedTeacher: function (value) {
      this.loading = true;
      apiClient
        .post("/schedules/filter", {
          query: {
            teacher: this.selectedTeacher,
            status: { $ne: "inactive" },
            hidden: { $ne: true },
          },
          aggregation: [
            {
              $lookup: {
                from: "teachers",
                localField: "teacher",
                foreignField: "_id",
                as: "teacher",
              },
            },
            {
              $lookup: {
                from: "subjects",
                localField: "subject",
                foreignField: "_id",
                as: "subject",
              },
            },
            {
              $lookup: {
                from: "modules",
                localField: "module",
                foreignField: "_id",
                as: "module",
              },
            },
            {
              $lookup: {
                from: "modulespecifics",
                localField: "moduleSpecific",
                foreignField: "_id",
                as: "moduleSpecific",
              },
            },
            {
              $lookup: {
                from: "rooms",
                localField: "room",
                foreignField: "_id",
                as: "room",
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classeRoom",
                foreignField: "_id",
                as: "classeRoom",
              },
            },
            {
              $project: {
                _id: 1,

                teacher: {
                  $let: {
                    vars: {
                      teacherVar: {
                        $arrayElemAt: ["$teacher", 0],
                      },
                    },
                    in: {
                      _id: "$$teacherVar._id",
                      employee: "$$teacherVar.employee",
                    },
                  },
                },
                classeRoom: {
                  $let: {
                    vars: {
                      classVar: {
                        $arrayElemAt: ["$classeRoom", 0],
                      },
                    },
                    in: {
                      name: "$$classVar.name",
                      _id: "$$classVar._id",
                    },
                  },
                },
                room: {
                  $let: {
                    vars: {
                      roomVar: {
                        $arrayElemAt: ["$room", 0],
                      },
                    },
                    in: {
                      name: "$$roomVar.name",
                      _id: "$$roomVar._id",
                    },
                  },
                },
                time: 1,
                day: 1,
                subject: {
                  $let: {
                    vars: {
                      subjectVar: {
                        $arrayElemAt: ["$subject", 0],
                      },
                    },
                    in: {
                      name: "$$subjectVar.name",
                      _id: "$$subjectVar._id",
                    },
                  },
                },
                module: {
                  $let: {
                    vars: {
                      moduleVar: {
                        $arrayElemAt: ["$module", 0],
                      },
                    },
                    in: {
                      name: "$$moduleVar.name",
                      _id: "$$moduleVar._id",
                      subjects: "$$moduleVar.subjects",
                    },
                  },
                },
                moduleSpecific: {
                  $let: {
                    vars: {
                      moduleSpecificVar: {
                        $arrayElemAt: ["$moduleSpecific", 0],
                      },
                    },
                    in: {
                      name: "$$moduleSpecificVar.name",
                      _id: "$$moduleSpecificVar._id",
                      subjects: "$$moduleSpecificVar.subjects",
                    },
                  },
                },
                ends: 1,
                duration: 1,
                type: 1,
                name: 1,
                includedSubjects: 1,
              },
            },
          ],
        })
        .then(({ data }) => {
          data = data.map((sched) => {
            if (sched.type == "MP") sched.subject = sched.module;
            else if (sched.type == "MS") sched.subject = sched.moduleSpecific;
            else sched.type = "S";

            sched.subject.type = sched.type || "S";

            if (["MS", "MP"].includes(sched.type) && !sched.includedSubjects) {
              sched.includedSubjects = sched.subject.subjects;
            }

            return sched;
          });

          this.data = data.filter(
            (sched) => sched.teacher._id == this.selectedTeacher
          );

          this.dataAllTeachers = data;

          this.loading = false;
        });
    },
    selectedTrimester: function (value) {
      this.loading = true;
      apiClient
        .post("/schedules/filter", {
          query: {
            status: { $ne: "inactive" },
            hidden: { $ne: true },
          },
        })
        .then(({ data }) => {
          data = data.map((sched) => {
            if (sched.type == "MP") sched.subject = sched.module;
            else if (sched.type == "MS") sched.subject = sched.moduleSpecific;
            else sched.type = "S";

            sched.subject.type = sched.type || "S";

            return sched;
          });

          this.data = data.filter(
            (sched) => sched.teacher._id == this.selectedTeacher
          );

          this.dataAllTeachers = data;
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
::v-deep .multiselect__option--highlight:after {
  background: #2facdd !important;
  outline: none;
  color: #fff;
}

::v-deep .multiselect__option--highlight {
  background: #2facdd !important;
  outline: none;
  color: #fff;
}
</style>
