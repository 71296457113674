<template>
  <div>
    <a-button
      v-if="selectedTeacher"
      type="primary"
      @click="showAccessDrawer"
      :loading="loading"
      :disabled="loading"
      ><a-icon type="plus-circle" />Ajouter niveau d'accées</a-button
    >
    <!-- START ACCESS SCHED DRAWER -->
    <a-drawer
      title="Ajouter des acceés supplémentaires à l'enseignant "
      :width="720"
      :visible="visibleAccessDrawer"
      @close="() => (visibleAccessDrawer = false)"
    >
      <a-form :form="form" layout="vertical" hide-required-mark>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item :label="$t('emploi.classe')">
              <a-select
                size="large"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                @change="
                  (val) => {
                    handleClassChange(val);
                    selectedSubject = null;
                  }
                "
                v-decorator="[
                  'classeRoom',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('emploi.choisirClasse'),
                      },
                    ],
                  },
                ]"
                :placeholder="$t('emploi.choisirClasse')"
              >
                <a-select-option
                  v-for="classe in filtredClasses"
                  :key="classe._id"
                  :value="classe._id"
                >
                  {{ classe.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="$t('emploi.matiere')">
              <multiselect
                v-model="selectedSubject"
                :disabled="!selectedLevel"
                :placeholder="$t('emploi.choisirMatiere')"
                :options="subjects"
                :customLabel="
                  () =>
                    selectedSubject
                      ? selectedSubject.name
                      : $t('emploi.choisirMatiere')
                "
                :close-on-select="true"
                :preserve-search="true"
                :maxHeight="400"
                label="_id"
                track-by="_id"
                :preselect-first="true"
                :multiple="false"
                :allow-empty="false"
              >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="align-text-top">{{ props.option.name }}</span>
                    <br />
                    <span
                      v-if="props.option.type == 'MP'"
                      class="option__small font-size-12 text-primary"
                    >
                      <b> module pédagogique</b>
                    </span>
                    <span
                      v-else-if="props.option.type == 'MS'"
                      class="option__small font-size-12 text-primary"
                    >
                      <b> module pilote</b>
                    </span>
                    <span
                      v-else-if="
                        props.option.type == 'S' && props.option.special
                      "
                      class="option__small font-size-12 text-danger"
                    >
                      <b>Select matière supplémentaire </b>
                    </span>
                    <span
                      v-else-if="props.option.type == 'S'"
                      class="option__small font-size-12"
                    >
                      <b>Select matière </b>
                    </span>
                  </div>
                </template>
              </multiselect>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <a-alert
        v-if="submitError"
        :message="submitError.message"
        :description="submitError.description"
        type="error"
        show-icon
      />
      <a-divider class="mb-3"
        >Les acceés supplémentaires de l'enseignant</a-divider
      >
      <!-- start sched access affichage-->
      <a-collapse v-model="activeKey" :loading="loading">
        <a-collapse-panel
          v-for="(accessScheds, classroomKey) in schedulesHidden"
          :key="classroomKey"
          :header="classroomKey"
        >
          <a-list :data-source="accessScheds">
            <a-list-item slot="renderItem" slot-scope="item">
              {{ item.subject.name }}

              <a slot="actions">
                <a-popconfirm
                  title="Sure?"
                  ok-text="Oui"
                  cancel-text="Non"
                  @confirm="deleteSchedule(item._id)"
                >
                  <a-button
                    type="danger"
                    class="ml-4"
                    shape="circle"
                    icon="delete"
                  /> </a-popconfirm
              ></a>
            </a-list-item>
          </a-list>
        </a-collapse-panel>
      </a-collapse>

      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button
          :style="{ marginRight: '8px' }"
          @click="() => (visibleAccessDrawer = false)"
        >
          {{ $t("action.annuler") }}
        </a-button>
        <a-button
          type="primary"
          @click="handleSubmit"
          :loading="loadingAddBtn"
          :disabled="loadingAddBtn"
        >
          {{ $t("action.ajouter") }}
        </a-button>
      </div>
    </a-drawer>
    <!-- END ACCESS SCHED DRAWER -->
  </div>
</template>
<script>
import apiClient from "@/services/axios";
import { mapState } from "vuex";
export default {
  props: {
    selectedTeacher: {
      required: false,
    },
    classes: {
      required: false,
    },
    selectedTrimester: {
      required: false,
    },
  },
  data() {
    return {
      activeKey: null,
      visibleAccessDrawer: false,
      loadingAddBtn: false,
      form: this.$form.createForm(this),
      filtredClasses: [],
      subjects: [],
      modules: [],
      selectedLevel: null,
      modulesSpecific: [],
      includedSubjects: [],
      selectedSubject: null,
      loadingSubjects: false,
      submitError: null,
      schedulesHidden: [],
      data: [],
      loading: false,
    };
  },

  methods: {
    deleteSchedule(schedId) {
      apiClient
        .delete(`/schedules/` + schedId)
        .then(() => {
          this.$message.success(this.$t("success.seanceSupp"));
          for (const classeName in this.schedulesHidden) {
            if (this.schedulesHidden.hasOwnProperty(classeName)) {
              // Access each property value
              this.schedulesHidden[classeName] = this.schedulesHidden[
                classeName
              ].filter((el) => el._id != schedId);
            }
          }
          this.getHiddenSchedules();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getHiddenSchedules() {
      this.loading = true;
      apiClient
        .post("/schedules/filter", {
          query: {
            status: { $ne: "inactive" },
            teacher: String(this.selectedTeacher),
            hidden: true,
          },
          aggregation: [
            {
              $lookup: {
                from: "subjects",
                localField: "subject",
                foreignField: "_id",
                as: "subject",
              },
            },
            {
              $project: {
                _id: 1,
                subject: {
                  $let: {
                    vars: {
                      subjectVar: {
                        $arrayElemAt: ["$subject", 0],
                      },
                    },
                    in: {
                      name: "$$subjectVar.name",
                      status: "$$subjectVar.status",
                      _id: "$$subjectVar._id",
                    },
                  },
                },
                classeRoom: 1,
              },
            },
          ],
        })
        .then(({ data }) => {
          data = data.filter((e) => e.subject.status != "inactive");
          this.schedulesHidden = data.reduce((result, item) => {
            const className = this.filtredClasses.find(
              (c) => c._id == item.classeRoom
            ).name;
            result[className] = (result[className] || []).concat(item);
            return result;
          }, {});
        })
        .finally(() => (this.loading = false));
    },
    async handleClassChange(value) {
      this.subjects = [];
      this.selectedLevel = this.classes.find(
        (item) => item._id === value
      ).level;

      this.loadingSubjects = true;
      apiClient
        .post("/subject/filter", {
          query: { status: "active" },
        })
        .then((res) => {
          res.data.map((m) => {
            m.type = "S";
            m.special = true;
            this.subjects.push(m);
          });
        })
        .finally(() => {
          this.loadingSubjects = false;
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async showAccessDrawer() {
      if (!this.selectedTeacher) {
        this.$message.error(this.$t("emploi.choisirEnseignant"));
        return;
      }
      this.loading = true;
      await apiClient
        .post("/schedules/disponiblity/filter", {
          query: {
            who: "teacher",
            time: "8:00",
            day: 0,
            teacher: this.selectedTeacher,
          },
        })
        .then(({ data }) => {
          this.filtredClasses = data.classes.disponible.concat(
            data.classes.indisponible
          );
        })
        .finally(() => {
          this.visibleAccessDrawer = true;
        });
      this.getHiddenSchedules();
    },
    handleSubmit(e) {
      this.submitError = null;
      e.preventDefault();

      this.form.rese;
      this.form.validateFields((err, values) => {
        //check existing access
        const classeName = this.filtredClasses.find(
          (c) => c._id == values.classeRoom
        ).name;
        if (
          this.schedulesHidden[classeName] &&
          this.schedulesHidden[classeName].find(
            (sched) => sched.subject._id == this.selectedSubject._id
          )
        ) {
          this.submitError = {
            message:
              "Acceés " +
              this.selectedSubject.name +
              " existant pour la classe " +
              classeName,
          };
          return;
        }

        //end check
        if (!err) {
          this.loadingAddBtn = true;
          values.teacher = this.selectedTeacher;
          values.trimester = this.selectedTrimester;
          values.day = null;
          values.time = null;
          values.type = "S";
          values.subject = this.selectedSubject._id;
          values.hidden = true;

          apiClient
            .put(`/schedules/accessSchedule`, { data: values })
            .then((res) => {
              res.data.subject.type = res.data.type;
              this.getHiddenSchedules();
              this.activeKey = classeName;
              this.$message.success("Niveau d'acceés ajouté avec succeés");
            })
            .catch((e) => {
              console.error(e);
              this.$message.error("Niveau d'acceés non ajouté");
            })
            .finally(() => (this.loadingAddBtn = false));
        }
      });
    },
  },
};
</script>
<style>
[data-kit-theme="default"] .stat-card .ant-card-body {
  padding: 10px !important;
  background: #eee;
  border-radius: 2px;
}
</style>
